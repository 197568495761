import React from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'
import { StaticImage } from 'gatsby-plugin-image';

class WhyShould extends React.Component {
  
  render() {

    return (
      <section className="whyshould pt-100 mb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2"><span className="bluecolor">Why Should </span> You Create an NFT Marketplace Using White Label NFT Marketplace Solution?</h2>
              <p className="pharagraph">
              Creating an NFT Marketplace from scratch can be difficult and takes time. A White Label NFT Marketplace solution offers a better and more productive way to go. When you pick a White Label option, you can begin your NFT Marketplace journey, and at a lower cost while taking advantage of the rising interest in NFTs. 
              </p>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-6 col-md-12 text-center d-none d-md-table-cell" >
            <StaticImage
                  src="https://coinsclone.mo.cloudinary.net/images/whitelabelnft/why-should-whitelabel-nft-marketplace.png"
                  alt="Benefits of White Label NFT Marketplace Development"
                  width={515}
                />
            </div>
            <div className="col-lg-6 col-md-12">
              <p>Here’s why it’s a game-changer for businesses:</p>
              <ul>
                <li className="pharagraph"><b>Easy-to-Use Design :</b> White Label options have simple and clear layouts, which help users browse, create, list, and buy or sell NFTs without trouble.</li>
                <li className="pharagraph"><b>Strong Safety Features :</b> These platforms come with built-in high-level safety measures to protect users and their deals from risks.</li>
                <li className="pharagraph"><b>Quicker Setup :</b> Building an NFT Marketplace from scratch can take a long time. On the other hand, White Label solutions are ready to use much sooner.</li>
                <li className="pharagraph"><b>Budget-Friendly Choice :</b> White Label solutions cost less than custom-built platforms allowing startups and small companies to afford them.</li>
                <li className="pharagraph"><b>Fast Market Launch :</b> A White Label NFT Marketplace lets businesses join the thriving crypto and NFT markets giving them an advantage over competitors and boosting their chances to make money.</li>
              </ul>
              <div className="banner-btn mt-4">
                <ButtonComponent />
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default WhyShould