import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'



class Invaluable extends React.Component {


  render() {

    return (
      <div className='pt-100'>
      <section className="usecase bgremove p-0 mb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h3 className="heading-h2">Our White Label NFT Marketplace <span className="heading-h3 bluecolor">Development Services</span></h3>
              <p>At Coinsclone, we offer a comprehensive suite of White Label NFT marketplace development services tailored to meet diverse business needs. Our solutions are feature-rich, secure, and designed to deliver a seamless user experience.</p>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-6 col-md-12">
              <h4 className='mb-3'>Smart Contract Creation and Audit</h4>
              <p>We build and rigorously test the NFT smart contracts to ensure transparent, secure, and automated transactions of digital assets within the NFT ecosystem.
              </p>
              <h4 className='mb-3'>Blockchain Integration Services</h4>
              <p>Our White Label solutions support top blockchains like Ethereum, Solana, Binance Smart Chain, and Polygon for scalable and efficient operations.</p>
              <h4 className='mb-3'>NFT Wallet Integration</h4>
              <p>Coinsclone’s White Label Solution comes with seamless integration of leading wallets, including MetaMask, Trust Wallet, and Coinbase Wallet, for secure and convenient payment handling.</p>
              <h4 className='mb-3'>Custom NFT Marketplace Development</h4>
              <p>We develop a fully customizable NFT Marketplace featuring unique branding, advanced functionalities, and tailored design elements that align with your business goals.</p>
            </div>
            <div className="col-lg-6 col-md-12  text-center d-none d-md-table-cell" >
                <StaticImage
                            src="https://coinsclone.mo.cloudinary.net/images/whitelabelnft/our-invaluable-nft-marketplace-services.png"
                            alt="White Label NFT Marketplace Software Development Services"
                            width={595}
                          />
            </div>
          </div>
        </div>
      </section>
      </div>
    )
  }
}

export default Invaluable