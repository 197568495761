import React from 'react'

const Diverce = () => {


  return (
    <section className="choosedesire ychoose otc various mb-0 pt-100">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <h2 className="heading-h2"><span className="heading-h3">Top White Label <span className="bluecolor">NFT Marketplace </span> </span>
            Softwares We Provide</h2>
          </div>
        </div>
        <div className="d-lg-flex firstblck">
          <div className="col pt-md-3" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="40px" height="40px" src="https://coinsclone.mo.cloudinary.net/images/whitelabelnft/oPensea.png" alt="White label of OpenSea Image1" />
                </div>
                <h3>
                White label of OpenSea 
                </h3>
              </div>
              <div>
                <p className="pharagraph">
                <a href='https://www.coinsclone.com/opensea-clone-script/'>OpenSea white label software</a> is preferred by a lot of startups who look for customizable solutions to build their NFT Marketplace on the Ethereum blockchain. OpenSea has covered most of the top features and has a massive trading volume. Using a White Label OpenSea solution, entrepreneurs can reach the market quickly.
                </p>
              </div>
            </div>
          </div>
          <div className="col pt-md-3" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="40px" height="40px" src="https://coinsclone.mo.cloudinary.net/images/whitelabelnft/rarible.png" alt="White label of Rarible Image1" />
                </div>
                <h3>
                White label of Rarible
                </h3>
              </div>
              <div>
                
                <p className="pharagraph">
                <a href='https://www.coinsclone.com/rarible-clone-script/'>Rarible white label software</a> is helpful for entrepreneurs who are in search of a P2P NFT Marketplace exactly similar to Rarible. The platform has a tremendous reach and creating one like Rarible on the Ethereum blockchain is a great idea. Acquire a White Label solution of Rarible from Coinsclone to make millions in a short span of time.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="d-lg-flex firstblck">
          <div className="col pt-md-3" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="40px" height="40px" src="https://coinsclone.mo.cloudinary.net/images/whitelabelnft/super-rare.png" alt="White label of SuperRare Image1" />
                </div>
                <h3>
                White label of SuperRare
                </h3>
              </div>
              <div>
                
                <p className="pharagraph">
                <a href='https://www.coinsclone.com/superrare-clone-script/'>White label SuperRare software</a> focuses on providing a ready-made NFT Marketplace solution based on Crypto art. SuperRare’s market cap has gone over $40 million and building an NFT Marketplace just like SuperRare can equally be a profitable business for crypto startups.
                </p>
              </div>
            </div>
          </div>
          <div className="col pt-md-3" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="40px" height="40px" src="https://coinsclone.mo.cloudinary.net/images/whitelabelnft/binance.png" alt="White label of Binance NFT Marketplace Image1" />
                </div>
                <h3>
                White label of Binance NFT Marketplace
                </h3>
              </div>
              <div>
                
                <p className="pharagraph">
                <a href='https://www.coinsclone.com/binance-nft-marketplace-clone-script/'>White label solution of Binance NFT Marketplace</a> offers a different kind of experience for users. Since Binance has a trusted community already, the white label of Binance NFT Marketplace has more benefits than most of the white label software.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="d-lg-flex firstblck">
          <div className="col pt-md-3" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="40px" height="40px" src="https://coinsclone.mo.cloudinary.net/images/whitelabelnft/magic_eden.png" alt="White Label of Magic Eden Image1" />
                </div>
                <h3>
                White Label of Magic Eden
                </h3>
              </div>
              <div>
                
                <p className="pharagraph">
                <a href='https://www.coinsclone.com/magic-eden-clone-script/'>White Label Solution of Magic Eden</a> provides an ideal solution for those aiming to launch an NFT Marketplace on the Solana blockchain. It is known for its low transaction fees and rapid performance. So it becomes a top choice for NFT traders. By using this customizable solution, entrepreneurs can quickly tap into the thriving Solana NFT ecosystem.
                </p>
              </div>
            </div>
          </div>
          <div className="col pt-md-3" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="40px" height="40px" src="https://coinsclone.mo.cloudinary.net/images/whitelabelnft/blur_icon.png" alt="White Label of Blur Image1" />
                </div>
                <h3>
                White Label of Blur
                </h3>
              </div>
              <div>
                
                <p className="pharagraph">
                <a href='https://www.coinsclone.com/blur-clone-script/'>Blur White Label software</a> is perfect for businesses looking to build a fast and efficient NFT Aggregator for professional traders. With advanced trading tools and gas-efficient transactions on the Ethereum blockchain, entrepreneurs can capitalize on the demand by creating an NFT Aggregator similar to Blur using a feature-rich White Label solution.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>  
  )
}

export default Diverce