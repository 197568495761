import React, { useState } from "react";
import { Accordion, Container } from 'react-bootstrap'



const FaqSection = () => {


  const [activeId, setActiveId] = useState('');

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <section className="faq pt-100">

      <Container>
        <div className="text-center">
          <h4 className="heading-h2"><span className="heading-h3 d-none d-md-block"><span className="bluecolor">FAQ</span></span> White Label NFT Marketplace
          </h4>
        </div>

        <Accordion defaultActiveKey={activeId}>
            <div className={activeId === '0' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('0')} className="panel-toggle" variant="link" eventKey="0">
                1. What is the actual cost of a White Label NFT Marketplace?
                </Accordion.Toggle>

              </div>
              <Accordion.Collapse eventKey="0">
                <div className="panel-body card-body">The White label NFT Marketplace cost ranges from $15,000 to $30,000. This totally depends on various factors such as the complexity of the project, required features, and blockchain integration. 
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '1' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('1')} className="panel-toggle" variant="link" eventKey="1">
                2. How long does it take to develop an NFT Marketplace using a White-Label solution?
                </Accordion.Toggle>

              </div>
              <Accordion.Collapse eventKey="1">
                <div className="panel-body card-body">Since our White Label customized NFT Marketplace is a readily available solution, it can be deployed in just seven to ten days. However, if you require complex and additional customizations, the time taken could slightly vary.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '2' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('2')} className="panel-toggle" variant="link" eventKey="2">
                3. Which blockchain network does your  White Label NFT Marketplace Software support?
                </Accordion.Toggle>

              </div>
              <Accordion.Collapse eventKey="2">
                <div className="panel-body card-body">Our White-label software supports multiple blockchains, including Ethereum, Binance Smart Chain, Polygon, Solana, Avalanche, and others. It allows users to select the blockchain that best suits their needs for transaction speed, cost, and scalability.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '3' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('3')} className="panel-toggle" variant="link" eventKey="3">
                4. Is it possible to integrate a Crypto Payment Gateway in your White Label software?
                </Accordion.Toggle>

              </div>

              <Accordion.Collapse eventKey="3">
                <div className="panel-body card-body">Yes, integrating a crypto payment gateway is a key feature, allowing users to pay in various cryptocurrencies such as Bitcoin, Ethereum, and others. It also supports wallet integrations like MetaMask, Trust Wallet, and others, making transactions seamless and secure.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '4' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('4')} className="panel-toggle" variant="link" eventKey="4">
                5. Can the platform be customized with unique features and branding?
                </Accordion.Toggle>

              </div>
              <Accordion.Collapse eventKey="4">
                <div className="panel-body card-body">Yes, the platform can be fully customized to reflect your brand identity, including design, user interface (UI), and user experience (UX). You can also add unique features like custom smart contracts, community governance, multi-language support, and more depending on your business requirements. 
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '5' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('5')} className="panel-toggle" variant="link" eventKey="5">
                6. Is your White Label NFT Marketplace compliant with legal requirements?
                </Accordion.Toggle>

              </div>
              <Accordion.Collapse eventKey="5">
                <div className="panel-body card-body">A White Label NFT Marketplace can be customized to comply with local and international legal frameworks, including KYC/AML requirements, intellectual property laws, and tax regulations. The platform can be designed to follow regional guidelines and to ensure data protection and privacy for users.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '6' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('6')} className="panel-toggle" variant="link" eventKey="6">
                7. Does the White Label NFT Marketplace need post-deployment support?
                </Accordion.Toggle>

              </div>
              <Accordion.Collapse eventKey="6">
                <div className="panel-body card-body">Post-deployment support is essential for ensuring the ongoing functionality of the marketplace. This includes software updates, bug fixes, security patches, new feature additions, and user support. Continuous monitoring and customer support are often required for smooth operations.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '7' ? 'panel-wrap active-panel card mb-0' : 'panel-wrap card mb-0'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('7')} className="panel-toggle" variant="link" eventKey="7">
                8. Is your White Label NFT Marketplace secure?
                </Accordion.Toggle>

              </div>
              <Accordion.Collapse eventKey="7">
                <div className="panel-body card-body">Yes, White Label NFT Marketplaces are built with high-level security standards, including smart contract audits, end-to-end encryption, and multi-factor authentication (MFA). Additionally, the platform is protected against common vulnerabilities like hacking, phishing, and DDoS attacks through regular security updates and monitoring.
                </div>
              </Accordion.Collapse>
            </div>
        </Accordion>
      </Container>

    </section>
  )
}

export default FaqSection