
import React from 'react'



class UsecaseOf extends React.Component {


  render() {

    return (
      <div className='cryptoex pt-100'>
      <section className="icon usecase mb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Diverse</span> White Label NFT Marketplace</span> Solutions We Provide</h3>
              <p>At Coinsclone, we offer a diverse range of White Label NFT Marketplace solutions that meet industry-specific needs.</p>
            </div>
          </div>
          <div className="row">
            <div className='col-lg-6 col-md-12 col-sm-12 col-12'>
              <div className='leftdiv'>
                <h4 className='head3 pl-3'>Art Auction Platforms</h4>
                <p className="pharagraph">Our White-Label Art Auction Platform enables online auctions where digital artists can list their creations, maximizing profits through competitive bidding from global collectors.</p>
              </div>
            </div>
            <div className='col-lg-6 col-md-12 col-sm-12 col-12' >
              <div className='leftdiv'>
                <h4 className='head3 pl-3'>Music Distribution and Collectibles</h4>
                <p className="pharagraph">Musicians can leverage our marketplace to sell their tracks, albums, and exclusive content while protecting their intellectual property and earning royalties from every sale.</p>
              </div>
            </div> 
          </div>
          <div className="row">
            <div className='col-lg-6 col-md-12 col-sm-12 col-12'>
              <div className='leftdiv'>
                <h4 className='head3 pl-3'>Real Estate Tokenization</h4>
                <p className="pharagraph">We provide a White-Label NFT Marketplace where real estate properties can be tokenized, allowing seamless buying, selling, and trading of assets without losing intrinsic value or property rights.</p>
              </div>
            </div>
            <div className='col-lg-6 col-md-12 col-sm-12 col-12' >
              <div className='leftdiv'>
                <h4 className='head3 pl-3'>Gaming Asset Trading Platform</h4>
                <p className="pharagraph">Our White-Label NFT Solution supports the trading of gaming assets, including gaming coins, screenshots, collectibles, and tutorials, creating a lucrative platform for gamers and developers.</p>
              </div>
            </div> 
          </div>
          <div className="row">
            <div className='col-lg-6 col-md-12 col-sm-12 col-12'>
              <div className='leftdiv'>
                <h4 className='head3 pl-3'>Memorabilia Marketplace</h4>
                <p className="pharagraph">Sports enthusiasts can tokenize memorable sports events, highlights, and collectibles, allowing fans to trade and own exclusive moments at high market value.</p>
              </div>
            </div>
            <div className='col-lg-6 col-md-12 col-sm-12 col-12' >
              <div className='leftdiv'>
                <h4 className='head3 pl-3'>Celebrity Collectibles Platform</h4>
                <p className="pharagraph">Our platform enables tokenization and trading of celebrity statements, exclusive content, and autographs, providing a unique fan engagement experience.</p>
              </div>
            </div> 
          </div>
        </div>
      </section>
      </div>
    )
  }
}

export default UsecaseOf