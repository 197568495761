import { StaticImage } from 'gatsby-plugin-image';
import React from 'react'

class TopFeatures extends React.Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {
    return (
      <section className="topfeaturez pt-100 mb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2"><span className="heading-h3 bluecolor">Key Features</span> Of Our White Label NFT Marketplace</h2>
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <h3 className="heading-h4">Seamless Wallet Integration</h3>
                <p className="pharagraph">Our software supports integration with popular crypto wallets like MetaMask, Coinbase Wallet, and Trust Wallet, allowing secure storage and transaction of NFTs without leaving the platform.
                </p>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 right-side order1 d-none d-md-table-cell" >
              <StaticImage
                  src="https://coinsclone.mo.cloudinary.net/images/whitelabelnft/marketplace.png"
                  alt="Marketplace"
                  width={478}
                />
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6  order1 d-none d-md-table-cell" > 
              <StaticImage
                  src="https://coinsclone.mo.cloudinary.net/images/whitelabelnft/support-multichain.png"
                  alt="Support Multichain"
                  width={478}
                />
            </div>
            <div className="col-md-12 col-lg-6 order2 ">
              <div className="top-right" >
                <h3 className="heading-h4">Advanced Auction and Bidding System</h3>
                <p className="pharagraph">The marketplace platform enables real-time bidding on NFTs with automated auction mechanisms, helping creators sell their digital assets at competitive prices.</p>
              </div>
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <h3 className="heading-h4">Cross-Chain Compatibility</h3>
                <p className="pharagraph">Our White Label Platform supports multiple blockchain networks, including Ethereum, Solana, and Polygon, providing users with scalability, reduced transaction costs, and broader market access.</p>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 right-side order1 d-none d-md-table-cell" >
            <StaticImage
                  src="https://coinsclone.mo.cloudinary.net/images/whitelabelnft/crypto-wallets.png"
                  alt="Crypto Wallets"
                  width={478}
                />
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6  order1 d-none d-md-table-cell" >
              <StaticImage
                  src="https://coinsclone.mo.cloudinary.net/images/whitelabelnft/decentralized-protocol.png"
                  alt="Decentralized protocol"
                  width={478}
                />
            </div>
            <div className="col-md-12 col-lg-6 order2 ">
              <div className="top-right" >
                <h3 className="heading-h4">Advanced Search and Filtering</h3>
                <p className="pharagraph">The platform offers dynamic search and filter functionalities, allowing users to easily explore NFTs based on categories, creators, prices, and popularity.</p>
              </div>
            </div>
          </div>
          <div className="row table-content orderflex mb-0">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <h3 className="heading-h4">Fractional Ownership</h3>
                <p className="pharagraph">The platform we create enables users to purchase fractional shares of high-value NFTs, making investments accessible to a broader audience.</p>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 right-side order1 d-none d-md-table-cell" >
            <StaticImage
                  src="https://coinsclone.mo.cloudinary.net/images/whitelabelnft/responsive-design.png"
                  alt="Responsive Design"
                  width={478}
                />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default TopFeatures