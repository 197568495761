
import React from 'react'



class Whatis extends React.Component {


  render() {

    return (
      <section className="whatis py-0 mb-0">
        <div className='gray-bg'>
        <div className="container">
        <h2 className="heading-h2 text-center"><span className="heading-h3 bluecolor">What is</span> White Label NFT Marketplace?</h2>
                <div className="text-center">
                <p className="pharagraph text-center" >White Label NFT Marketplace is a pre-built software solution designed to create a platform for minting, buying, and selling NFTs. It is an efficient and cost-effective way to launch your NFT Marketplace business. By adopting our White Label service, you can customize and modify features and functionalities according to your business requirements. Moreover, an NFT Marketplace is one of top business ideas for entrepreneurs because it provides several revenue streams.
              </p>
              <p className="pharagraph text-center mb-0" >As a premier White Label NFT Marketplace development company, Coinsclone provides secure and feature-rich NFT Marketplace software, specially designed for startups. Our solutions are highly customizable, enabling businesses to tailor the platform to their unique requirements and branding. We implement advanced security protocols to ensure a safe trading environment for users. With quick deployment and scalable infrastructure, we help startups seamlessly enter the booming NFT market and gain a competitive edge.
              </p>
                </div>
        </div>
        </div>
        </section>
    )
  }
}

export default Whatis